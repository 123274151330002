<template>
  <div class="container mx-auto">
    <div class="w-1/2 mx-auto text-center rounded bg-gray-200 p-4">
      <div class="flex justify-center" v-if="!error">
        <svg
          class="animate-spin h-16 w-16 text-indigo mx-auto mb-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <p class="text-1xl font-semibold">Attempting login using token</p>

      <!--  -->
      <div class="flex mb-6 justify-center" v-if="error">
        <p class="text-red-700 font-semibold">
          There seems to have been an error, perhaps you are using an expired
          token
        </p>
      </div>
      <router-link class="btn btn-gray" to="/login" v-if="error"
        >Try again</router-link
      >
    </div>
  </div>
</template>

<script>
import "@/assets/styles/tailwind.css";
export default {
  data() {
    return {
      error: false,
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    acceptLoginToken() {
      if (this.token) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/accept-login-token", {
            token: this.token,
            requester: "clients-portal",
          })
          .then(({ data }) => {
            if (data.error == "invalid-credentials") {
              this.error = true;
            } else if (data.error == "tfa_required") {
              this.$router.push(data.url);
            } else {
              this.$store.user = data.user;
              this.$store.token = data.token;

              localStorage.setItem("user", JSON.stringify(data));
              if (data.token) {
                this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
              }

              this.$router.push({ name: "DashboardView" });
              // this.$store.dispatch("login", data).then(() => {});
            }
          });
      }
    },
  },
  mounted() {
    this.acceptLoginToken();
  },
};
</script>

<style>
</style>
